import "./App.css";

import React, { useEffect, useContext } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./localization/i18n";
import { UserContextProvider } from "context/UserContext";
import { VideoContext, VideoContextProvider } from "context/VideoContext";
import {
    LoadingContext,
    LoadingContextProvider,
    actionTypes,
} from "context/LoadingContext";
import { history } from "router/history";
import { useLoadingOverlayDelay } from "hooks/useLoadingOverlayDelay";
import { useFetchRoom } from "hooks/api/useFetchRoom";

import { ChangePin } from "components/ChangePin/ChangePin";
import { ErrorBoundary } from "components/Base/ErrorBoundary/ErrorBoundary";
import { Events } from "components/Events/Events";
import { Home } from "components/Home/Home";
import { MainLayout } from "./components/Layout/MainLayout";
import { NotFound } from "./components/NotFound/NotFound";
import { ProtectedRoute } from "./components/Base/ProtectedRoute/ProtectedRoute";
import { PublicLayout } from "./components/Layout/PublicLayout";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import { Settings } from "components/Settings/Settings";
import { StudyInfo } from "components/StudyInfo/StudyInfo";
import { Pdf } from "components/Pdf/Pdf";
import { LoadingOverlay } from "components/Base/LoadingOverlay/LoadingOverlay";
import { VideoOverlay } from "components/VideoOverlay/VideoOverlay";

import SigninOidc from 'components/Login/signin-oidc'
import { Login } from "components/Login/Login";

import SignoutOidc from 'components/Logout/signout-oidc'
import { Logout } from "components/Logout/logout";

import {
    ShowLoadingContext,
    ShowLoadingContextProvider,
} from "context/ShowLoadingContext";

function Main() {
    const { i18n, ready: translationsReady } = useTranslation(undefined, {
        useSuspense: false,
    });

    const { room } = useContext(VideoContext);
    const { isLoading, dispatch } = useContext(LoadingContext);
    const { showLoading, setShowLoading } = useContext(ShowLoadingContext);
    const showLoadingOverlay = useLoadingOverlayDelay(isLoading);

    const { pathname } = useLocation();
    const isPdfMode = pathname.startsWith("/pdf/");
    const { room: activeRoom } = useFetchRoom({ shouldFetch: !isPdfMode });

    useEffect(() => {
        setShowLoading(showLoadingOverlay);
    }, [setShowLoading, showLoadingOverlay]);

    useEffect(() => {
        const LOADING_KEY = "translations";
        const type = translationsReady
            ? actionTypes.REMOVE_LOADING_ITEM
            : actionTypes.ADD_LOADING_ITEM;

        dispatch({
            type,
            key: LOADING_KEY,
        });
    }, [dispatch, translationsReady]);

    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.language;

    return (
        <ErrorBoundary>
            <LoadingOverlay show={showLoading} />
            {translationsReady && (
                <Switch>
                    <Route path="/signout-oidc" component={SignoutOidc} />
                    <Route path="/signin-oidc" component={SigninOidc} />
                    <Route path="/logout" component={Logout} />
                    <Route path={["/login","/change-pin"]}>
                        <PublicLayout>
                            <ErrorBoundary>
                                <Switch>
                                    <Route path="/login" component={Login}/>
                                    <ProtectedRoute
                                        path="/change-pin"
                                        component={ChangePin}
                                    />
                                </Switch>
                            </ErrorBoundary>
                        </PublicLayout>
                    </Route>
                    <ProtectedRoute path="/pdf/:formId">
                        <Pdf />
                    </ProtectedRoute>
                    <Route path="/">
                    <VideoOverlay room={room} activeRoom={activeRoom} />
                        <MainLayout>
                            <ErrorBoundary>
                                <Switch>
                                    <ProtectedRoute
                                        path="/"
                                        component={Home}
                                        exact
                                    />
                                    <ProtectedRoute
                                        path="/study"
                                        component={StudyInfo}
                                    />
                                    <ProtectedRoute
                                        path="/events"
                                        component={Events}
                                    />
                                    <ProtectedRoute
                                        path="/unscheduled-events"
                                        component={Events}
                                    />
                                    <ProtectedRoute
                                        path="/settings"
                                        component={Settings}
                                    />
                                    <Route component={NotFound} />
                                </Switch>
                            </ErrorBoundary>
                        </MainLayout>
                    </Route>
                </Switch>
            )}
        </ErrorBoundary>
    );
}

export function App() {
    return (
        <Router history={history}>
            <LoadingContextProvider>
                <ShowLoadingContextProvider>
                    <VideoContextProvider>
                        <UserContextProvider>
                            <ScrollToTop />
                            <Main />
                        </UserContextProvider>
                    </VideoContextProvider>
                </ShowLoadingContextProvider>
            </LoadingContextProvider>
        </Router>
    );
}
