import userManager, { signoutRedirect, signinRedirect } from "utils/oidcClientExtension";
import { history } from "router/history";
import { IDENTITY_CONFIG } from "utils/identityConfig";
import { User } from "oidc-client";
import { getCookie,eraseCookie } from "helpers/cookies";

userManager.events.addUserSignedOut(async () => {
    history.push('/signout-oidc');
});

//Returns promise to notify the parent window of response from the authorization endpoint.
userManager.signinSilentCallback();

const isLoggedIn = () => {
    const token = getLocalToken();
    return token != null;
};

const logout = async () => {
    eraseCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
    await signoutRedirect();
};

const onLoginRedirect =() => signinRedirect();

function getLocalToken (){
    // since we turned on automatic silent renew token so this local storage value will update for each time renew
    // automatic renew will be execute base on accessTokenExpiringNotificationTime configuration
    // https://github.com/IdentityModel/oidc-client-js/wiki
    var localStorageValue = localStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`);
    if(localStorageValue){
      return User.fromStorageString(localStorageValue).access_token;
    }

    // we already change the way to store access token from cookie to local storage
    // but worker generate pdf still use this cookie to generate so it's kind of fallback token for worker
    return getCookie(process.env.REACT_APP_TOKEN_COOKIE_NAME);
 }

export const authenticationService = {
    logout,
    isLoggedIn,
    onLoginRedirect,
    getLocalToken
};
