import {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
  useMemo,
  useContext,
} from "react";
import { useParams } from "react-router-dom";

import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { useForm } from "react-hook-form";

import { formReducer, types } from "./shared/formReducer";
import { formService } from "services/form";
import { useFetchForm } from "hooks/api/useFetchForm";
import { useQuery } from "hooks/useQuery";
import { LoadingContext, actionTypes } from "context/LoadingContext";

import { Box } from "components/Base/Box/Box";
import { FormContent } from "./FormContent/FormContent";
import { FormHeader } from "./FormHeader/FormHeader";
import { FormNavigation } from "./FormNavigation/FormNavigation";
import { FormSubmitPage } from "./FormSubmitPage/FormSubmitPage";
import { FormThankYouPage } from "./FormThankYouPage/FormThankYouPage";
import { isReadOnly } from "./shared/form-helpers";

import "./Form.css";

export function Form() {
  const history = useHistory();
  const params = useQuery();
  const { eventId, page } = useParams();
  const { path } = useRouteMatch();
  const location = useLocation();

  const formHeader = useRef(null);
  const parsedPage = useRef(parseInt(page));

  const [{ form }, dispatch] = useReducer(formReducer, {
    form: { itemGroups: [] },
  });

  const readOnly = useMemo(() => isReadOnly(form?.status), [form]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentItemGroup, setCurrentItemGroup] = useState();
  const validateOnLoad = useRef(false);
  const visibleItemGroups = form.itemGroups.filter((ig) => ig.isVisible);
  const pageCount = visibleItemGroups.length;
  const displayPageCount = readOnly ? pageCount : pageCount + 1; // includes submit page when not readonly
  const isThankYouPage = location.pathname.includes("thank-you");
  const isReadyToSubmit = useRef(false);
  const [submitFailed, setSubmitFailed] = useState();
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const { dispatch: dispatchLoading } = useContext(LoadingContext);
  const [showFormNavigation, setShowFormNavigation] = useState(false);

  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
    handleSubmit,
    resetField,
  } = useForm({ delayError: 500, mode: "onChange" });

  const goToPage = (toPage) =>
    history.push(`/events/${eventId}/form/${toPage}`);

  const submitForm = () => {
    // Prevent user from submitting form multiple times
    // This can happen if user submits form again in the time window
    // between first submission and the dispatch loading is completed
    setIsSubmitting(true);

    const LOADING_KEY = "submit-form";
    dispatchLoading({
      type: actionTypes.ADD_LOADING_ITEM,
      key: LOADING_KEY,
    });

    formService
      .submitForm(eventId, form.formData)
      .then((response) => {
        if (response.success) {
          history.push(`/events/${eventId}/form/thank-you`);
        } else {
          setSubmitErrorMessage(response.message);
          setSubmitFailed(true);
        }
      })
      .catch((e) => setSubmitFailed(true))
      .finally(() => {
        dispatchLoading({
          type: actionTypes.REMOVE_LOADING_ITEM,
          key: LOADING_KEY,
        });
        setIsSubmitting(false);
      });
  };

  const { error, isLoading } = useFetchForm(eventId, (data) =>
    dispatch({
      type: types.SET_FORM,
      payload: data,
    })
  );

  useEffect(() => {
    const LOADING_KEY = "form";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatchLoading({
      type,
      key: LOADING_KEY,
    });
  }, [dispatchLoading, isLoading]);

  useEffect(() => {
    parsedPage.current =
      page === "submit" || isThankYouPage ? pageCount + 1 : parseInt(page);
  }, [isThankYouPage, page, pageCount]);

  useEffect(() => {
    const pageIsOutOfRange =
      pageCount && (parsedPage.current === 0 || parsedPage.current > pageCount);

    if (pageIsOutOfRange) {
      history.push("./1");
    }
  }, [history, pageCount]);

  useEffect(
    () => setCurrentItemGroup(visibleItemGroups[parsedPage.current - 1]),
    [visibleItemGroups]
  );

  useEffect(() => {
    validateOnLoad.current = params.get("validate") === "true";

    if (validateOnLoad.current) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemGroup]);

  useEffect(() => !submitFailed && setSubmitErrorMessage(), [submitFailed]);

  useEffect(() => {
    setShowFormNavigation(false);
  }, [page]);

  const setItemValue = useCallback(
    (id, value, format) => {
      dispatch({
        type: types.SET_ITEM_VALUE,
        payload: { id, value, format },
      });
    },
    [dispatch]
  );

  const getNavigation = () => {
    return (
      <FormNavigation
        currentPage={parsedPage.current}
        readOnly={readOnly || isSubmitting}
        pageCount={displayPageCount}
        isThankYouPage={isThankYouPage}
        isSubmitPage={page === "submit"}
        onClickPrevious={(page) => {
          if (page < 1) {
            history.push(`/events`);
          } else {
            clearErrors();
            setSubmitFailed(false);
            goToPage(page);
            setCurrentItemGroup(visibleItemGroups[page]);
          }
        }}
        onClickNext={(page) => {
          handleSubmit(() => {
            page > pageCount
              ? history.push(`/events/${eventId}/form/submit`)
              : goToPage(page);
          })();
        }}
        onSubmit={() => {
          if (isReadyToSubmit.current) {
            submitForm();
          }
        }}
      />
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Box footer={getNavigation()} showFooter={showFormNavigation}>
        <FormHeader
          currentPage={parsedPage.current ? parsedPage.current : pageCount}
          formName={form.formName}
          isPending={isLoading}
          isReadyToSubmit={isReadyToSubmit.current}
          isThankYouPage={isThankYouPage}
          pageCount={displayPageCount}
          ref={formHeader}
          showFetchError={!!error}
          valid={Object.keys(errors).length === 0}
        />
        {form && (
          <Switch>
            <Route path={`${path}(\\d+)`}>
              <FormContent
                control={control}
                errors={errors}
                formData={form.formData}
                itemGroups={[currentItemGroup]}
                readOnly={readOnly || isSubmitting}
                onAnimationEnd={() => {
                  setShowFormNavigation(true);
                }}
                onChange={setItemValue}
                onReset={(id) => resetField(id)}
                formHeaderRef={formHeader}
              />
            </Route>
            <Route path={`/events/:eventId/form/submit`}>
              <FormSubmitPage
                submitFailed={submitFailed}
                submitErrorMessage={submitErrorMessage}
                itemGroups={visibleItemGroups}
                formData={form.formData}
                onAnimationEnd={() => {
                  setShowFormNavigation(true);
                }}
                onSubmitValidation={(valid) =>
                  (isReadyToSubmit.current = valid)
                }
              />
            </Route>
            <Route path={`/events/:eventId/form/thank-you`}>
              <FormThankYouPage
                onAnimationEnd={() => {
                  setShowFormNavigation(true);
                }}
              />
            </Route>
          </Switch>
        )}
      </Box>
    </>
  );
}
