import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { i18n } from "localization/i18n";

import { GridSection } from "../Layout/GridSection";
import { ChangePinForm } from "./ChangePinForm";
import { Footer } from "components/Layout/Footer/Footer"; 

export function ChangePin() {
  const { t } = useTranslation();

  return (
    <GridSection>
      <h1 className="grid__title">{t("change_pin.must_change_pin")}</h1>
      <ChangePinForm className="grid__form" />
      <Footer alignText="left" className="grid__footer" />
    </GridSection>
  );
}
